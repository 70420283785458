import ErrorBoundary from '@wt/shared/components/error/ErrorBoundary';
import useMediaQuery from '@wt/shared/hooks/useMediaQuery';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { getMapkitToken } from '@wt/shared/components/maps/mapkit/config';
import MapContext from '../context/MapContext';
import forwardMapkitEvent from '../util/forwardMapkitEvent';
import load from '../util/loader';
import {
  ColorScheme,
  Distances,
  FeatureVisibility,
  fromMapKitMapType,
  fromMapKitRegion,
  LoadPriority,
  MapType,
  PointOfInterestCategory,
  toMapKitColorScheme,
  toMapKitCoordinateRegion,
  toMapKitDistances,
  toMapKitFeatureVisibility,
  toMapKitLoadPriority,
  toMapKitMapType,
  toMapKitPOICategory,
} from '../util/parameters';
import MapProps from './MapProps';
import useOrigin from '@wt/shared/hooks/useOrigin/useOrigin';

const InnerMap = React.forwardRef<
  mapkit.Map | null,
  React.PropsWithChildren<Omit<MapProps, 'token'>>
>(
  (
    {
      children = undefined,

      load: customLoad,

      colorScheme = ColorScheme.Light,
      mapType = MapType.Standard,
      distances = Distances.Adaptive,
      loadPriority = LoadPriority.PointsOfInterest,

      isRotationEnabled = true,
      isScrollEnabled = true,
      isZoomEnabled = true,
      showsCompass = FeatureVisibility.Hidden,
      showsScale = FeatureVisibility.Hidden,
      showsMapTypeControl = false,
      showsZoomControl = true,
      showsUserLocationControl = false,
      showsPointsOfInterest = true,
      showsUserLocation = false,
      tracksUserLocation = false,
      allowWheelToZoom = true,

      includedPOICategories = undefined,
      /**
       * City names were not showing up on the map,
       * excluding all the POI Categories *seems* to ensure the city names appear.
       * When zoomed in enough, all POIs still seem to be loaded as well.
       */
      excludedPOICategories = [
        PointOfInterestCategory.Airport,
        PointOfInterestCategory.AmusementPark,
        PointOfInterestCategory.Aquarium,
        PointOfInterestCategory.ATM,
        PointOfInterestCategory.Bakery,
        PointOfInterestCategory.Bank,
        PointOfInterestCategory.Beach,
        PointOfInterestCategory.Brewery,
        PointOfInterestCategory.Cafe,
        PointOfInterestCategory.Campground,
        PointOfInterestCategory.CarRental,
        PointOfInterestCategory.EVCharger,
        PointOfInterestCategory.FireStation,
        PointOfInterestCategory.FitnessCenter,
        PointOfInterestCategory.FoodMarket,
        PointOfInterestCategory.GasStation,
        PointOfInterestCategory.Hospital,
        PointOfInterestCategory.Hotel,
        PointOfInterestCategory.Laundry,
        PointOfInterestCategory.Library,
        PointOfInterestCategory.Marina,
        PointOfInterestCategory.MovieTheater,
        PointOfInterestCategory.Museum,
        PointOfInterestCategory.NationalPark,
        PointOfInterestCategory.Nightlife,
        PointOfInterestCategory.Park,
        PointOfInterestCategory.Parking,
        PointOfInterestCategory.Pharmacy,
        PointOfInterestCategory.Police,
        PointOfInterestCategory.PostOffice,
        PointOfInterestCategory.PublicTransport,
        PointOfInterestCategory.Restaurant,
        PointOfInterestCategory.Restroom,
        PointOfInterestCategory.School,
        PointOfInterestCategory.Stadium,
        PointOfInterestCategory.Store,
        PointOfInterestCategory.Theater,
        PointOfInterestCategory.University,
        PointOfInterestCategory.Winery,
        PointOfInterestCategory.Zoo,
      ],

      paddingTop = 0,
      paddingRight = 0,
      paddingBottom = 0,
      paddingLeft = 0,

      initialRegion = undefined,
      cameraBoundary = undefined,
      minCameraDistance = undefined,
      maxCameraDistance = undefined,

      onLoad = undefined,

      onRegionChangeStart = undefined,
      onRegionChangeEnd = undefined,
      onMapTypeChange = undefined,

      onSingleTap = undefined,
      onDoubleTap = undefined,
      onLongPress = undefined,

      onUserLocationChange = undefined,
      onUserLocationError = undefined,

      onClick = undefined,
      onMouseMove = undefined,
      onMouseDown = undefined,
      onMouseUp = undefined,
    },
    mapRef
  ) => {
    const origin = useOrigin();

    const [map, setMap] = useState<mapkit.Map | null>(null);
    const element = useRef<HTMLDivElement>(null);
    const exists = useRef<boolean>(false);

    // Load the map
    useEffect(() => {
      if (!origin) return;
      const token = getMapkitToken(origin);
      const loadMap = typeof customLoad === 'function' ? customLoad : load;
      loadMap(token).then(() => {
        if (exists.current) return;
        const options = initialRegion
          ? {
              region: toMapKitCoordinateRegion(initialRegion),
              loadPriority: toMapKitLoadPriority(loadPriority),
            }
          : {};
        setMap(
          new mapkit.Map(element.current!, {
            ...options,
            showsScale: mapkit.FeatureVisibility.Visible,
          })
        );
        exists.current = true;
      });

      return () => {
        if (map) {
          map.destroy();
          exists.current = false;
        }
      };
    }, [origin]);

    // Fire the `onLoad` event
    useEffect(() => {
      if (map !== null) {
        onLoad?.();
      }
    }, [map]);

    // Expose the map using a forward ref
    useImperativeHandle(mapRef, () => map!, [map]);

    const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    // Enum properties
    useEffect(() => {
      if (!map) return;
      map.colorScheme = toMapKitColorScheme(colorScheme, isDarkMode);
    }, [map, colorScheme, isDarkMode]);
    useEffect(() => {
      if (!map) return;
      map.mapType = toMapKitMapType(mapType);
    }, [map, mapType]);
    useEffect(() => {
      if (!map) return;
      map.distances = toMapKitDistances(distances);
    }, [map, distances]);

    // Simple values properties
    const booleanProperties = {
      isRotationEnabled,
      isScrollEnabled,
      isZoomEnabled,
      showsMapTypeControl,
      showsZoomControl,
      showsUserLocationControl,
      showsPointsOfInterest,
      showsUserLocation,
      tracksUserLocation,
    };
    Object.entries(booleanProperties).forEach(([propertyName, prop]) => {
      useEffect(() => {
        if (!map) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map[propertyName] = prop;
      }, [map, prop]);
    });

    // Feature visibility properties
    const featureVisibilityProperties = {
      showsCompass,
      showsScale,
    };
    Object.entries(featureVisibilityProperties).forEach(
      ([propertyName, prop]) => {
        useEffect(() => {
          if (!map) return;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          map[propertyName] = toMapKitFeatureVisibility(prop);
        }, [map, prop]);
      }
    );

    // Experimental feature
    useEffect(() => {
      if (!map) return;
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        map._allowWheelToZoom = allowWheelToZoom;
      } catch {
        /* ignore errors */
      }
    }, [map, allowWheelToZoom]);

    // Padding
    useEffect(() => {
      if (!map) return;
      map.padding = new mapkit.Padding(
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft
      );
    }, [map, paddingTop, paddingRight, paddingBottom, paddingLeft]);

    // Camera boundary
    useEffect(() => {
      if (!map) return;
      if (!cameraBoundary) return;
      map.cameraBoundary = { region: toMapKitCoordinateRegion(cameraBoundary) };
    }, [map, cameraBoundary]);

    // Camera zoom range
    useEffect(() => {
      if (!map) return;
      if (!minCameraDistance || !maxCameraDistance) return;
      try {
        map.cameraZoomRange = new mapkit.CameraZoomRange(
          minCameraDistance,
          maxCameraDistance
        );
      } catch (e) {
        /* ignore errors */
      }
    }, [map, minCameraDistance, maxCameraDistance]);

    // Point of interest filter
    useEffect(() => {
      if (!map) return;

      if (includedPOICategories && excludedPOICategories) {
        throw new Error(
          'Can’t specify both includedPOICategories and excludedPOICategories.'
        );
      } else if (includedPOICategories) {
        map.pointOfInterestFilter = mapkit.PointOfInterestFilter.including(
          includedPOICategories.map(toMapKitPOICategory)
        );
      } else if (excludedPOICategories) {
        map.pointOfInterestFilter = mapkit.PointOfInterestFilter.excluding(
          excludedPOICategories.map(toMapKitPOICategory)
        );
      } else {
        map.pointOfInterestFilter = mapkit.PointOfInterestFilter.including([]);
      }
    }, [map, includedPOICategories, excludedPOICategories]);

    // MapKit JS events
    const regionHandler = () => fromMapKitRegion(map!.region);
    forwardMapkitEvent(
      map,
      'region-change-start',
      onRegionChangeStart,
      regionHandler
    );
    forwardMapkitEvent(
      map,
      'region-change-end',
      onRegionChangeEnd,
      regionHandler
    );
    forwardMapkitEvent(map, 'map-type-change', onMapTypeChange, () =>
      fromMapKitMapType(map!.mapType)
    );

    type MapKitMapInteractionEvent = {
      domEvents: Event[];
      pointOnPage: DOMPoint;
    };
    const interactionEvent = ({
      domEvents,
      pointOnPage,
    }: MapKitMapInteractionEvent) => ({
      domEvents,
      pointOnPage,
      toCoordinates: () => map!.convertPointOnPageToCoordinate(pointOnPage),
    });
    forwardMapkitEvent(map, 'single-tap', onSingleTap, interactionEvent);
    forwardMapkitEvent(map, 'double-tap', onDoubleTap, interactionEvent);
    forwardMapkitEvent(map, 'long-press', onLongPress, interactionEvent);

    type MapKitUserLocationChangeEvent = {
      coordinate: mapkit.Coordinate;
      timestamp: Date;
      floorLevel: number | undefined | null;
    };
    forwardMapkitEvent(
      map,
      'user-location-change',
      onUserLocationChange,
      ({
        coordinate: { latitude, longitude },
        timestamp,
        floorLevel,
      }: MapKitUserLocationChangeEvent) => ({
        coordinate: { latitude, longitude },
        timestamp,
        floorLevel,
      })
    );
    type MapKitUserLocationErrorEvent = {
      code: 1 | 2 | 3 | 4;
      message: string;
    };
    forwardMapkitEvent(
      map,
      'user-location-error',
      onUserLocationError,
      ({ code, message }: MapKitUserLocationErrorEvent) => ({ code, message })
    );

    // Native JavaScript events
    const domEvents = [
      { name: 'click', handler: onClick },
      { name: 'mousemove', handler: onMouseMove },
      { name: 'mousedown', handler: onMouseDown },
      { name: 'mouseup', handler: onMouseUp },
    ] as const;
    domEvents.forEach(({ name, handler }) => {
      useEffect(() => {
        if (!map || !handler) return undefined;

        const listener = (e: MouseEvent) => {
          handler({
            domEvents: [e],
            pointOnPage: { x: e.pageX, y: e.pageY },
            toCoordinates() {
              const { latitude, longitude }: mapkit.Coordinate =
                map.convertPointOnPageToCoordinate(
                  new DOMPoint(e.pageX, e.pageY)
                );
              return { latitude, longitude };
            },
          });
        };

        element.current?.addEventListener(name, listener);
        return () => element.current?.removeEventListener(name, listener);
      }, [map, handler]);
    });

    return (
      <React.StrictMode>
        <div style={{ width: '100%', height: '100%' }} ref={element}>
          <MapContext.Provider value={map}>{children}</MapContext.Provider>
        </div>
      </React.StrictMode>
    );
  }
);

const Map = React.forwardRef<
  mapkit.Map | null,
  React.PropsWithChildren<Omit<MapProps, 'token'>>
>((props, ref) => {
  return (
    <ErrorBoundary>
      <InnerMap ref={ref} {...props} />
    </ErrorBoundary>
  );
});

export default Map;
